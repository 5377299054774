var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ODataTable',{ref:"table",attrs:{"load":_vm.load,"url":_vm.url,"headers":_vm.headers,"item-key":"listingId","filter":_vm.filter,"filters":_vm.filters,"sort-by":['listingName'],"must-sort":"","expanded":_vm.expanded,"single-expand":"","dense":"","show-expand":"","show-select":"","itemsPerPage":+20},on:{"update:expanded":function($event){_vm.expanded=$event},"item-selected":_vm.onSelectionChanged,"current-items":_vm.onItemsChanged,"click:row":_vm.onRowClicked},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
var select = ref.select;
var item = ref.item;
return [_c('v-checkbox',{staticClass:"mt-0",attrs:{"value":isSelected,"aria-label":(isSelected? 'checked ' :'not checked ') + 'Select ' + item.listingName,"tabindex":"0"},on:{"input":select,"change":function($event){return _vm.onSelectionChanged(item, !isSelected)}}})]}},{key:"item.listingName",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"start","no-gutters":""}},[_c('v-col',[_c('a',{attrs:{"href":item.docsUrl,"aria-label":'View details for ' + item.listingName,"target":"_blank"}},[_vm._v(_vm._s(item.listingName))])])],1)]}},{key:"item.supportPolicy",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":_vm.getSupportPolicyUrl(item.supportPolicy),"target":"_blank"}},[_vm._v(_vm._s(item.supportPolicy))])]}},{key:"item.supportStartDate",fn:function(ref){
var item = ref.item;
return [(item.supportStartDate)?_c('span',[_vm._v(_vm._s(_vm._f("date")(item.supportStartDate)))]):_vm._e()]}},{key:"item.endDate1",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":_vm.getTitle(item)}},[_vm._v(_vm._s(_vm._f("date")(item.endDate1)))])]}},{key:"item.links",fn:function(ref){
var item = ref.item;
return [(item.links[0])?_c('a',{attrs:{"href":item.links[0].url,"target":"_blank"}},[_vm._v(_vm._s(item.links[0].label))]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-5",attrs:{"colspan":headers.length}},[_c('h1',{staticClass:"mb-2"},[_vm._v(_vm._s(item.name)+" Releases")]),_c('ProductReleaseTable',{attrs:{"graphQueryProduct":item,"releases":item.releases,"value":_vm.value},on:{"item-selected":function($event){return _vm.onReleaseSelected(item, $event)}}})],1)]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
return [(item.releases.length > 1)?_c('v-icon',{attrs:{"tabindex":"0","aria-expanded":isExpanded.toString(),"small":""},on:{"click":_vm.toggleExpansion}},[_vm._v(_vm._s(isExpanded ? '$collapse' : '$expand'))]):_vm._e()]}},{key:"header.data-table-select",fn:function(){return undefined},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }