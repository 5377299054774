
































import Vue from 'vue'
import { groupBy } from 'lodash-es'
import IProductEndDate from '../models/IProductEndDate'

export default Vue.extend({
  props: {
    value: Array as () => IProductEndDate[],
    title: String,
    color: String,
    loading: Boolean
  },
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    resultsGroupedByDay() {
      return groupBy(this.value, x => {
        return x.endDate
      })
    }
  },
  methods: {
  }
})
